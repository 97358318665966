import { removeTrailingForwardSlash } from "../util";

export enum BackendTier {
    Nonclinical = 'nonclinical',
    Clinical = 'clinical',
}

export class BackendDefinition {
    name: string;
    url: string;

    constructor(name: string, url: string) {
        this.name = name;
        this.url = url;
    }
}

// this class models a supported backend
export class Backend {
    tier: BackendTier;
    name: string;
    url: string;

    constructor(backendDefinition: BackendDefinition, tier: BackendTier) {
        this.name = backendDefinition.name;
        this.url = removeTrailingForwardSlash(backendDefinition.url);
        this.tier = tier;
    }
}

export class Backends {
    backends: { [backendName: string]: Backend };
    defaultBackend: string;

    constructor(backends: { [backendName: string]: Backend }, defaultBackend: string) {
        this.backends = backends;
        this.defaultBackend = defaultBackend;

        if (!backends[defaultBackend]) {
            throw new Error(`Given default backend ${defaultBackend} does not exist in supplied backend collection`);
        }
    }

    addBackend(backend: Backend) {
        if (this.backends[backend.name]) {
            throw new Error(`Backend ${backend.name} already exists in backend collection`);
        }

        this.backends[backend.name] = backend;
    }

    getBackend(name: string): Backend {
        if (!this.backends[name]) {
            throw new Error(`No backend named ${name} in backend collection`);
        }

        return this.backends[name];
    }

    getBackendByUrl(url: string): Backend {
        const cleanedUrl = removeTrailingForwardSlash(url.trim());
        let backend = Object.values(this.backends).find(b => b.url === cleanedUrl);
        if (!backend) {
            
            // backwards compatibility -- if current URL contains "seg" try to replace it with "ai"
            if (cleanedUrl.includes('.seg.')) {
                // special case for "eu2", otherwise just perform the replacement
                const backwardsCompatibilityFixedUrl = cleanedUrl.includes('eu2.seg.') ? cleanedUrl.replace('eu2.seg.', 'eu.ai.') : cleanedUrl.replace('.seg.', '.ai.');
                backend = Object.values(this.backends).find(b => b.url === backwardsCompatibilityFixedUrl);
            }

            if (!backend) {
                throw new Error(`No backend with URL ${cleanedUrl} in backend collection`);
            }
        }

        return backend;
    }

    getDefaultBackend(): Backend {
        return this.backends[this.defaultBackend];
    }
}