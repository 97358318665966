import {  BackendDefinition } from "./web-apis/backends";
import { getFilenameSafeTimestamp } from "./util";
import { newGuid } from "./dicom/guid";

// *** ENVIRONMENT TYPES ***

export const isDemo = () => {
    return window.location.hostname.toLowerCase().includes("mvisiondemo") || isLNDemo();
}

export const isLNDemo = () => {
    return window.location.hostname.toLowerCase().includes("mvisiondemoln");
}

export const isLocalhost = () => {
    // doesn't catch every possible corner case but it's good enough
    return (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1");
}

/** 
 * Returns TRUE if this is a Rutherford-specific deployment, false otherwise.
 * Rutherford deployment has certain tools and features disabled, and
 * some specific ones enabled that aren't available in regular
 * environments.
 */
export const isRutherford = () => {
    return window.location.hostname.toLowerCase().includes("rutherford");
}

/** RTViewer version displayed to users. (The "actual" version of RTViewer is the Git commit hash.) */
export const RTViewerDisplayVersion = '3.2.2';


// *** BACKENDS ***

// contouring backends
//
// ADDING NEW BACKENDS (for both livereview and contouring use):
//  1. Add new backend definitions to this block. The first argument is the name of the backend in UI (if it ends up
//     being user-selectable), the second is the URL to the backend.
//  2. Optional: add the new backend definitions to the userAllowedBackends array below if users should be able to select them
//     from UI (if they have the user permission flag to be able to change contouring backends)
//  3. Required: add the new backend definitions to the `backends` collection in auth.tsx. Make sure the backend tiers (clinical,
//     nonclinical) are correct.
//
// TODO: this process could be simplified a bit, or read from a config file
//
export const devBackend = new BackendDefinition('Dev nonclinical (dev)',                    'https://dev.ai.api.mvision.ai');
export const qaBackend = new BackendDefinition('QA clinical (qa)',                          'https://qa.ai.api.mvision.ai');
export const qaNonclinicalBackend = new BackendDefinition('QA nonclinical (preview.qa)',    'https://preview.qa.ai.api.mvision.ai');
export const demoBackend = new BackendDefinition('Demo nonclinical (demo)',                 'https://demo.ai.api.mvision.ai');
export const euClinicalBackend = new BackendDefinition('EU clinical',                       'https://eu.ai.api.mvision.ai');
export const euNonclinicalBackend = new BackendDefinition('EU nonclinical',                 'https://preview.eu.ai.api.mvision.ai');
export const ukClinicalBackend = new BackendDefinition('UK clinical',                       'https://uk.ai.api.mvision.ai');
export const ukNonclinicalBackend = new BackendDefinition('UK nonclinical',                 'https://preview.uk.ai.api.mvision.ai');
export const previewWestEuropeBackend = new BackendDefinition('1.1 West Europe (preview)',  'https://mvd-api-previewwesteurope.azurewebsites.net');
export const westEuropeBackend = new BackendDefinition('1.1 West Europe (clinical)',        'https://mvd-api-westeurope.azurewebsites.net');
export const slowClinicalBackend = new BackendDefinition('Slow backend (clinical)',         'https://slow.ai.api.mvision.ai');
export const slowNonclinicalBackend = new BackendDefinition('Slow backend (nonclinical)',   'https://preview.slow.ai.api.mvision.ai');
export const usClinicalBackend = new BackendDefinition('US clinical',                       'https://us.ai.api.mvision.ai');
export const usNonclinicalBackend = new BackendDefinition('US nonclinical',                 'https://preview.us.ai.api.mvision.ai');
export const chClinicalBackend = new BackendDefinition('CH clinical',                       'https://ch.ai.api.mvision.ai');
export const chNonclinicalBackend = new BackendDefinition('CH nonclinical',                 'https://preview.ch.ai.api.mvision.ai');
export const frClinicalBackend = new BackendDefinition('FR clinical',                       'https://fr.ai.api.mvision.ai');
export const frNonclinicalBackend = new BackendDefinition('FR nonclinical',                 'https://preview.fr.ai.api.mvision.ai');
export const auClinicalBackend = new BackendDefinition('AU clinical',                       'https://au.ai.api.mvision.ai');
export const auNonclinicalBackend = new BackendDefinition('AU nonclinical',                 'https://preview.au.ai.api.mvision.ai');
export const researchBackend = new BackendDefinition('Research',                            'https://research.ai.api.mvision.ai');


// other backends (that still need MSAL authentication)
export const rtViewerBackend = new BackendDefinition('RTViewer backend API', 'https://dev.backend.rtviewer.mvision.ai/');

// This is the list of backends rtviewer allows users to change to and use for contouring.
// It can be a subset of the entire list of all available backends.
// NOTE: the order of this array will affect the order they are presented in for the user
// in the configuration dropdown.
export const userAllowedBackends = [
    demoBackend,
    devBackend,
    qaBackend,
    qaNonclinicalBackend,
    researchBackend,
    euNonclinicalBackend,
    euClinicalBackend,
    ukNonclinicalBackend,
    ukClinicalBackend,
    previewWestEuropeBackend,
    westEuropeBackend,
    slowClinicalBackend,
    slowNonclinicalBackend,
    usClinicalBackend,
    usNonclinicalBackend,
    chClinicalBackend,
    chNonclinicalBackend,
    frClinicalBackend,
    frNonclinicalBackend,
    auClinicalBackend,
    auNonclinicalBackend,
];


/// *** APP REGISTRATIONS, AUTHORIZATIONS ***

export const MVAPI_AUTH = 'MVAPI_AUTH';
export const MVDAPIPROD_AUTH = 'MVDAPIPROD_AUTH';

export const MVISION_AUTHORITY = 'https://login.microsoftonline.com/mvision.ai';

export const mvapiClientId = 'ebe6f1a7-69db-46b0-85aa-334284979346';
// export const mvapiUrl = 'https://mvapi.azurewebsites.net';

export const mvdapiprodClientId = '49dd79c5-077f-4fa1-a055-4752e20025bd';
// export const mvdapiprodUrl = 'https://mvd-api-westeurope.azurewebsites.net';

// default auths are needed for rtviewer to work -- these must be logged into on app start
export const defaultAuths = [MVAPI_AUTH];



// *** OTHER GLOBALS ***

export const DEFAULT_APP_NAME = 'MVision RTViewer';
export const APP_NAME_SHORT = 'rtviewer';
export const getAppName = () => DEFAULT_APP_NAME;

// *** CLIENT ID ***

/** Client ID is used to identify this client/session in API calls. */
let clientId: string;

/** (Re-)generates and sets a new client ID. */
export function generateNewClientId() {
    clientId = `${APP_NAME_SHORT}-${getFilenameSafeTimestamp()}-${newGuid()}`.substring(0, 64);
    console.info(`Client ID: ${clientId}`);
}

/** Returns the current client ID. */
export function getClientId(): string {
    if (!clientId) {
        generateNewClientId();
    }
    
    return clientId;
}
